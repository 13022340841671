import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router,ParamMap} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-pago-factura',
  templateUrl: './pago-factura.component.html',
  styleUrls: ['./pago-factura.component.css']
})
export class PagoFacturaComponent implements OnInit {
  // Se crean las variables globales
  Identificacion:string = "";
  NumDocumento:string = "";
  CodigoSap:string = "";
  InfoCliente:string = "";
  NombreCompleto:string = "";
  TipoDocumento:string = "-1";
  TotalFactura:number=0;
  TotalSinIva:number = 0;
  Iva:number = 0;
  Descuento:number = 0;
  InfoFactura = [];
  Email:string = "";
  Telefono:number;
  NumeroDocumento:string = "";
  Nombres:string = "";
  Apellidos:string = "";
  CodigoSeguridad = "";


  constructor(private serviciosService:ServiciosService,private activatedRoute:ActivatedRoute,private router:Router,private spinnerService: NgxSpinnerService) { }

  ngOnInit() {
    // Se catura cambio de parametro
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
      this.spinnerService.show();
      //Se captura el Id del Catalogo
      this.Identificacion = params.get('Id');
      this.NumDocumento = params.get('NumDocumento');
      this.CodigoSeguridad = params.get('Codigo');
      this.serviciosService.getConsultarCliente(this.Identificacion)
      .subscribe((data:[])=>{

          var RespuestaVerificacion = [];
          RespuestaVerificacion = data;
          var Error = RespuestaVerificacion['Error'];
          this.CodigoSap = RespuestaVerificacion['CodigoSap'];
          this.NombreCompleto = RespuestaVerificacion['NombreCompleto'].toUpperCase();

          // Se valida si hay error
          if(Error == "1")
          {
            this.spinnerService.hide();
            this.router.navigate(['inicio']);
          }
          if(this.CodigoSap == "")
          {
            this.spinnerService.hide();
            this.router.navigate(['inicio']);
          }

          //Se realiza la consulta de la informacion de la fatura
          this.serviciosService.ConsultaFactura(this.CodigoSap,this.NumDocumento)
          .subscribe((data:[])=>{
            this.InfoFactura = data;
            var NumFactura = this.InfoFactura['Vbeln'];
            var VerificaFactura = NumFactura.substr(0,3);
            this.TotalSinIva = Math.round(this.InfoFactura['Skfbt']);
            this.Descuento = Math.round(this.InfoFactura['Wskto']);

            // Se verifica si se tiene algun descuento
            if(this.Descuento > 0)
            {
              this.TotalFactura = Math.round(this.InfoFactura['Wrbtr']) - Math.round(this.InfoFactura['Wskto']);
            }
            else{
              this.TotalFactura = Math.round(this.InfoFactura['Wrbtr']);
            }

            //Se realiza el calculo del Iva
            if(VerificaFactura == "PRO" || VerificaFactura == "BUF")
            {
              this.Iva = this.TotalFactura - this.TotalSinIva;
            }
            else{
              this.Iva = Math.round(this.TotalSinIva * 0.05);
            }
            this.spinnerService.hide();
          });
      });
    });
  }


  //-------------------------------------------------------------
  // Funcion para iniciar el proceso de pago
  //-------------------------------------------------------------
  InicioPago()
  {
    // Se verifican que los campos esten llenos
    if(this.Email !="" && this.TipoDocumento !="-1" && this.NumeroDocumento !="" && this.Nombres != "" && this.Apellidos != "" && this.Telefono > 0 && this.TotalFactura > 0 )
    {
      this.spinnerService.show();

      //Se valida el Email
      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.Email)){
       } else {
        this.spinnerService.hide();
        alert("La dirección de email es incorrecta.");
        return;
       }
       //Se valida el campo numero de documento
       if(/^([0-9])*$/i.test(this.NumeroDocumento))
       {}else{
        this.spinnerService.hide();
         alert("El campo numero de documento no debe contener letras.");
         return;
       }
        //Se valida el campo telefono
        if(/^([0-9])*$/i.test(this.Telefono.toString()))
        {}else{
          this.spinnerService.hide();
          alert("El campo telefono de documento no debe contener letras.");
          return;
        }
        //Se valida el valor a pagar
        if(/^([0-9])*$/i.test(this.TotalFactura.toString()))
        {}else{
          this.spinnerService.hide();
          alert("El campo valor a pagar de documento no debe contener letras.");
          return;
        }

      // Se realiza la consulta del token
      this.serviciosService.getConsultarToken()
      .subscribe((data:[])=>{
        // captura la respuesta del servicio
        var RespuestaToken = data;
        var Verifica = RespuestaToken['Error'];
        var Token = RespuestaToken['Token'];

        // Se verifica la respuesta del servicio del token
        if(Verifica == "0")
        {
          var IdPago = this.NumDocumento;
          var DescPago = "Pago Factura "+ IdPago;

          //Se ejecuta el servicio para amacenar la informacion en la sonda
          this.serviciosService.getAlmacenarPagoSonda(Token,IdPago,this.Identificacion,this.CodigoSap,"0")
          .subscribe((data:[])=>{
            var RespuentaPagoSonda = [];
            RespuentaPagoSonda = data;
            var VerificaRespuestaSonda = RespuentaPagoSonda['Error'];
            
            // Se verifica la respuesta del servicio
            if(VerificaRespuestaSonda == "0")
            {
              // Se realiza el consumo del servicio del inicio pagos
              this.serviciosService.InicioPagoZV(this.TotalFactura,this.Iva,IdPago,DescPago,this.Email,this.NumeroDocumento,this.TipoDocumento,this.Nombres,this.Apellidos,this.Telefono)
              .subscribe((data:[])=>{
                var InicioPagos = [];
                var CodigoInicio = [];
                InicioPagos = data;
                CodigoInicio = InicioPagos["soap:Envelope"]["soap:Body"][0]["inicio_pagoV2Response"][0]["inicio_pagoV2Result"][0];
                //Productivo
                window.location.href ="https://www.zonapagos.com/t_Marpicopas/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio;
                //Calidad
                //window.location.href ="https://www.zonapagos.com/t_Marpicosa2/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio;
                this.router.navigate(['inicio']);
              });
            }
            else{
              this.spinnerService.hide();
              alert("Se presento un error intente mas tarde.")
            }
          });
        }
        else{
          this.spinnerService.hide();
          alert("se presento un Error con el inicio de pago, intente mas tarde. ERROR TOKEN")
        }
      });
    }
    else{
      this.spinnerService.hide();
      alert("Faltan campos por llenar, por favor verifique.")
    }
  }

}
