import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  // Se crean las variables globales
  Identificacion:string="";
  TipoDocumento:string = "-1";
  Salida = [];

  constructor(private serviciosService:ServiciosService,private router:Router,private spinnerService: NgxSpinnerService) { }

  ngOnInit() {
  }

  //-------------------------------------------------------------
  //Funcion para verificar el nit
  //-------------------------------------------------------------
  VerificaNit()
  {
    //Se verifica que el campo este lleno
    if(this.Identificacion != "" && this.TipoDocumento !="-1")
    {
      this.spinnerService.show();
      //Se crean las variables de verificación
      var Error:string = "";
      var CodigoSap:string = "";
      var Contador:string = "";
      var Nombre:string = "";

      // Se realiza el consumo del servicio
      this.serviciosService.getConsultarCliente(this.Identificacion)
      .subscribe((data:[])=>{
        this.Salida = data;

        //Se verifica la información de la salida
        Error = this.Salida['Error'];
        CodigoSap = this.Salida['CodigoSap'];
        Contador = this.Salida['Verifica'];
        Nombre = this.Salida['NombreCompleto'];

        //Se verifica que sea un solo registros
        if(Error == "0" && CodigoSap !="" )
        {
          this.router.navigate(['opcionesPago/'+this.Identificacion]);
        }
        else{
          if(Error = "0")
          {
            this.spinnerService.hide();
            //Si no se encuentra registrado para Vinos se verifica Promocionales
            alert("El numero de identificación ingresado no se encuentra registrado.");
          }
          else{
            this.spinnerService.hide();
            alert("Se presento un error por favor comuniquese con cartera.")
          }
        }

      });
    }
    else{
      alert("Debe ingresar la información del campo identificación.")
    }
  }

}
