import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router,ParamMap} from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-estado-cuenta',
  templateUrl: './estado-cuenta.component.html',
  styleUrls: ['./estado-cuenta.component.css']
})
export class EstadoCuentaComponent implements OnInit {
  //Se crean las variables globales
  Identificacion:string = "";
  CodigoSeguridad:string = "";
  CodigoSap:string="";
  NombreCompleto:string = "";
  InfoCliente:string = "";
  Facturas = [];
  EstadoCuenta = [];
  IfEstadoCuenta:boolean=true;
  IfPendiente:boolean=false;
  IfRechazado:boolean=false;
  IfAprobado:boolean = false;
  IfFormPago:boolean = false;
  DocPagoRetoma:string = "";
  FacturasSeleccionadas = [];
  TotalPagar:number = 0;
  IvaPagar:number = 0;
  Email:string = "";
  Telefono:number;
  TipoDocumento:string = "-1";
  NumeroDocumento:string = "";
  Nombres:string = "";
  Apellidos:string = "";


  constructor(private serviciosService:ServiciosService,private activatedRoute:ActivatedRoute,private router:Router,private spinnerService: NgxSpinnerService) { }

  ngOnInit() {
    // Se catura cambio de parametro
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
      //Se captura el Id del Catalogo
      this.Identificacion = params.get('Id');
      this.CodigoSeguridad = params.get('Codigo');
      this.spinnerService.show();
      //Se verifica el codigo de seguridad
      this.serviciosService.VerificaCodigo(this.Identificacion,this.CodigoSeguridad)
      .subscribe((data:[])=>{
        var InfoCodigo = data;
        var Error = InfoCodigo['Error'];
        
        //se verifica la salida
        if(Error == "1")
        {
          this.spinnerService.hide();
          this.router.navigate(['inicio']);
        }
      });

      // Se realiza verificacion del nit
      this.serviciosService.getConsultarCliente(this.Identificacion)
      .subscribe((data:[])=>{
        var RespuestaVerificacion = [];
        RespuestaVerificacion = data;
        var Error = RespuestaVerificacion['Error'];
        this.CodigoSap = RespuestaVerificacion['CodigoSap'];
        this.NombreCompleto = RespuestaVerificacion['NombreCompleto'].toUpperCase();

        // Se valida si hay error
        if(Error == "1")
        {
          this.spinnerService.hide();
          this.router.navigate(['inicio']);
        }
        if(this.CodigoSap == "")
        {
          this.spinnerService.hide();
          this.router.navigate(['inicio']);
        }
        //Se captura la fecha actual el mes y el dia
        var FechaActual = new Date();
        var Mes = parseInt(FechaActual.getUTCMonth().toString()) + 1;
        var Dia = parseInt(FechaActual.getDate().toString());

        //Se verifica la longitud del dia
         // Se verifica la longitud del mes
         if(Dia.toString().length ==1 )
         {
           var DiaReal = "0"+ Dia.toString();
         }
         else
         {
           var DiaReal = Dia.toString();
         }

        // Se verifica la longitud del mes
        if(Mes.toString().length ==1 )
        {
          var MesReal = "0"+ Mes.toString();
        }
        else
        {
          var MesReal = Mes.toString();
        }

        // Se crea la variable de la fecha
        var Fecha = FechaActual.getFullYear() + "-" + MesReal +"-"+ DiaReal;

        // Se ejecuta el servicio
        this.serviciosService.EstadoCuenta(this.CodigoSap,Fecha)
        .subscribe((data:[])=>{
          this.Facturas = data;
          //Se recorren los registros del estado de cuenta
          for(var Registro in this.Facturas)
          {
            //se capturan las variables
            var Factura = this.Facturas[Registro].Xblnr;
            var NumInterno = this.Facturas[Registro].Belnr;
            var TipoDocumento =this.Facturas[Registro].Blart;
            var Valor = parseInt(this.Facturas[Registro].Dmbtr);
            var FechaDoc = (new Date(parseInt(this.Facturas[Registro].Bldat.substr(6)))).getFullYear()+"/" +((new Date(parseInt(this.Facturas[Registro].Bldat.substr(6)))).getUTCMonth() +1) + "/" +(new Date(parseInt(this.Facturas[Registro].Bldat.substr(6)))).getUTCDate();

            //Se verifica que sea una factura
            if((TipoDocumento == "RV") && NumInterno != "")
            {
              //Se verifica el tipo de Documento para agregarlo al array
              this.EstadoCuenta.push({"Factura":Factura,"FechaDoc":FechaDoc,"NumInterno":NumInterno,"Valor":Valor,"Checked":false});
            }
          }
          this.spinnerService.hide();
        });
      });
    });
  }

  //---------------------------------------------------------------------
  //Se verifica el estado del pago para proceder al formulario de pago
  //---------------------------------------------------------------------
  VerificaPago(NumDocumento:string)
  {
    var InfoEstadoPago=[];
    this.spinnerService.show();
    //Se realiza la consulta para verificar el estado del pago
    this.serviciosService.ConsultaEstadoPago(NumDocumento)
    .subscribe((data:[])=>{
      InfoEstadoPago = data;

      //Se verifica si no hay registros para ese pago
      if(InfoEstadoPago == null)
      {
        this.spinnerService.hide();
        this.router.navigate(['pagoFactura/'+this.Identificacion+'/'+NumDocumento+'/'+this.CodigoSeguridad]);
      }
      else{
        var Estado = InfoEstadoPago["estadoRecibido"];

        // Se verifica el estado del pago
        if(Estado == "888" || Estado == "999")
        {
          this.DocPagoRetoma = NumDocumento;
          this.IfPendiente = true;
          this.IfEstadoCuenta = false;
        }
        else if(Estado == "1000" || Estado == "1002" || Estado == "4000" || Estado == "4003"|| Estado == "4002")
        {
          this.DocPagoRetoma = NumDocumento;
          this.IfRechazado = true;
          this.IfEstadoCuenta = false;
        }
        else if(Estado == "1")
        {
          this.DocPagoRetoma = NumDocumento;
          this.IfAprobado = true;
          this.IfEstadoCuenta = false;
        }
        this.spinnerService.hide();
      }
    });
  }

  //----------------------------------------------------------------------
  //Regresar al estado de cuenta
  //----------------------------------------------------------------------
  Regresar()
  {
    location.reload()
  }
  //----------------------------------------------------------------------
  //Regresar al estado de cuenta desde el formulario de pago
  //----------------------------------------------------------------------
  RegresarEstado()
  {
    this.IfFormPago = false;
    this.IfEstadoCuenta = true;
  }

  //----------------------------------------------------------------------
  // Funcion para agregar o quitar factura
  //----------------------------------------------------------------------
  CheckItem(NumDocumento:string)
  {
    //Funcion para la respuesta de estado de pago
    var InfoEstadoPago=[];
    var InfoFactura=[];

    //Definicion de variables
    var Check =(<HTMLInputElement>document.getElementById('Check'+NumDocumento)).checked;

    //Se valida la seleccion 
    if(Check) //Activo
    {
      this.spinnerService.show();
      //Se realiza la consulta para verificar el estado del pago
      this.serviciosService.ConsultaEstadoPago(NumDocumento)
      .subscribe((data:[])=>{
        InfoEstadoPago = data;

        //Se verifica si no hay registros para ese pago
        if(InfoEstadoPago == null)
        {
          var InfoDocAgrupado = []
          //Se realiza la consulta del documento para connfirmar si esta en un pago agrupado
          this.serviciosService.ConsultaDocAgrupado(NumDocumento)
          .subscribe((data:[])=>{
            InfoDocAgrupado = data;
            var VerificaDocAgrupado = InfoDocAgrupado["Error"];

            if(VerificaDocAgrupado == "0")
            {
              // Se captura la informacion si esta en estado pendiente
              var VerificaPendiente = InfoDocAgrupado["Verifica"];
              if(VerificaPendiente == "0")
              {
                //Se realiza la consulta de la informacion de la fatura
                this.serviciosService.ConsultaFactura(this.CodigoSap,NumDocumento)
                .subscribe((data:[])=>{
                  InfoFactura = data;
                  var NumFactura = InfoFactura['Vbeln'];
                  var VerificaFactura = NumFactura.substr(0,3);
                  var TotalFactura:number=0;
                  var TotalSinIva = Math.round(InfoFactura['Skfbt']);
                  var Descuento = Math.round(InfoFactura['Wskto']);
                  var Iva:number = 0;

                  // Se verifica si se tiene algun descuento
                  if(Descuento > 0)
                  {
                    TotalFactura = Math.round(InfoFactura['Wrbtr']) - Math.round(InfoFactura['Wskto']);
                  }
                  else{
                    TotalFactura = Math.round(InfoFactura['Wrbtr']);
                  }

                  //Se realiza el calculo del Iva
                  if(VerificaFactura == "PRO" || VerificaFactura == "BUF")
                  {
                    Iva = TotalFactura - TotalSinIva;
                  }
                  else{
                    Iva = Math.round(TotalSinIva * 0.05);
                  }
                  this.TotalPagar = this.TotalPagar + TotalFactura;
                  this.IvaPagar = this.IvaPagar + Iva;

                  //Se agrega el registro
                  this.FacturasSeleccionadas.push({"NumDocumento":NumDocumento,"Factura":NumFactura,"Valor":TotalFactura,"Iva":Iva});
                  
                  //Se ajuste el Item que cambio
                  this.EstadoCuenta.map(function(Registro){
                    if(Registro.NumInterno == NumDocumento){
                      Registro.Checked = true;
                    }
                  });
                  this.spinnerService.hide();
                });
              }
              else
              {
                this.spinnerService.hide();
                Swal.fire({
                  title: 'Alerta',
                  text: 'No se puede seleccionar la factura porque se encuentra en estado pendiente.',
                  icon: 'warning'
                });

                //Se inactiva la selección
                (<HTMLInputElement>document.getElementById('Check'+NumDocumento)).checked = false;
                return;
              }
            }
            else{
              this.spinnerService.hide();
              Swal.fire({
                title: 'Alerta',
                text: 'No se puede seleccionar la factura porque se encuentra en estado pendiente.',
                icon: 'warning'
              });
  
              //Se inactiva la selección
              (<HTMLInputElement>document.getElementById('Check'+NumDocumento)).checked = false;
              return;
            }
          });
        }
        else{
          // Se captura el estado
          var Estado = InfoEstadoPago["estadoRecibido"];

          // Se verifica el estado del pago
          if(Estado == "888" || Estado == "999")
          {
            this.spinnerService.hide();
            Swal.fire({
              title: 'Alerta',
              text: 'No se puede seleccionar la factura porque se encuentra en estado pendiente.',
              icon: 'warning'
            });

            //Se inactiva la selección
            (<HTMLInputElement>document.getElementById('Check'+NumDocumento)).checked = false;
            return;
          }
          else if(Estado == "1000" || Estado == "1002" || Estado == "4000" || Estado == "4003" || Estado == "4002")
          {
            var InfoDocAgrupado = []
            //Se realiza la consulta del documento para connfirmar si esta en un pago agrupado
            this.serviciosService.ConsultaDocAgrupado(NumDocumento)
            .subscribe((data:[])=>{
              InfoDocAgrupado = data;
              var VerificaDocAgrupado = InfoDocAgrupado["Error"];
  
              if(VerificaDocAgrupado == "0")
              {
                // Se captura la informacion si esta en estado pendiente
                var VerificaPendiente = InfoDocAgrupado["Verifica"];
                if(VerificaPendiente == "0")
                {
                  //Se realiza la consulta de la informacion de la fatura
                  this.serviciosService.ConsultaFactura(this.CodigoSap,NumDocumento)
                  .subscribe((data:[])=>{
                    InfoFactura = data;
                    var NumFactura = InfoFactura['Vbeln'];
                    var VerificaFactura = NumFactura.substr(0,3);
                    var TotalFactura:number=0;
                    var TotalSinIva = Math.round(InfoFactura['Skfbt']);
                    var Descuento = Math.round(InfoFactura['Wskto']);
                    var Iva:number = 0;
  
                    // Se verifica si se tiene algun descuento
                    if(Descuento > 0)
                    {
                      TotalFactura = Math.round(InfoFactura['Wrbtr']) - Math.round(InfoFactura['Wskto']);
                    }
                    else{
                      TotalFactura = Math.round(InfoFactura['Wrbtr']);
                    }
  
                    //Se realiza el calculo del Iva
                    if(VerificaFactura == "PRO" || VerificaFactura == "BUF")
                    {
                      Iva = TotalFactura - TotalSinIva;
                    }
                    else{
                      Iva = Math.round(TotalSinIva * 0.05);
                    }
                    this.TotalPagar = this.TotalPagar + TotalFactura;
                    this.IvaPagar = this.IvaPagar + Iva;
  
                    //Se agrega el registro
                    this.FacturasSeleccionadas.push({"NumDocumento":NumDocumento,"Factura":NumFactura,"Valor":TotalFactura,"Iva":Iva});
                            
                    //Se ajuste el Item que cambio
                    this.EstadoCuenta.map(function(Registro){
                      if(Registro.NumInterno == NumDocumento){
                        Registro.Checked = true;
                      }
                    });
                    this.spinnerService.hide();
                  });
                }
                else
                {
                  this.spinnerService.hide();
                  Swal.fire({
                    title: 'Alerta',
                    text: 'No se puede seleccionar la factura porque se encuentra en estado pendiente.',
                    icon: 'warning'
                  });
  
                  //Se inactiva la selección
                  (<HTMLInputElement>document.getElementById('Check'+NumDocumento)).checked = false;
                  return;
                }
              }
              else{
                this.spinnerService.hide();
                Swal.fire({
                  title: 'Alerta',
                  text: 'No se puede seleccionar la factura porque se encuentra en estado pendiente.',
                  icon: 'warning'
                });
    
                //Se inactiva la selección
                (<HTMLInputElement>document.getElementById('Check'+NumDocumento)).checked = false;
                return;
              }
            });
          }
          else if(Estado == "1")
          {
            this.spinnerService.hide();
            Swal.fire({
            title: 'Alerta',
            text: 'No se seleccionar la factura porque se encuentra en estado aprobado.',
            icon: 'warning'
          });
          //Se inactiva la selección
          (<HTMLInputElement>document.getElementById('Check'+NumDocumento)).checked = false;
          return;
          }
        }
      });
    }
    else //Inactivo
    {
      //Se Verifica la ventas del mes 
      var Verifica = this.FacturasSeleccionadas.find(Registro => Registro.NumDocumento == NumDocumento); 

      // Se verifica el filtro
      if(Verifica != undefined)
      {
        this.TotalPagar = this.TotalPagar - Verifica['Valor'];
        this.IvaPagar = this.IvaPagar - Verifica['Iva'];
      }

      //Se remueve el filtro seleccionado
      this.FacturasSeleccionadas = this.FacturasSeleccionadas.filter(function(FacturasSeleccionadas){
        return FacturasSeleccionadas.NumDocumento !==NumDocumento;
      });

      //Se ajuste el Item que cambio
      this.EstadoCuenta.map(function(Registro){
        if(Registro.NumInterno == NumDocumento){
          Registro.Checked = false;
        }
      });
    }
  }

  //----------------------------------------------------------------------
  // funcion para pagar las facturas
  //----------------------------------------------------------------------
  Pagar()
  {
    // Se verifican si hay pagos agregados
    var NumFacturas:number = this.FacturasSeleccionadas.length;
    this.spinnerService.show();
    //Se valida el numero de registros
    if(NumFacturas === 0)
    {
      this.spinnerService.hide();
      Swal.fire({
        title: 'Alerta',
        text: 'No ha seleccionado ninguna factura por favor verifique.',
        icon: 'warning'
      });
    }
    else if(NumFacturas === 1)
    {
      //Se obtiene el numero del pago
      var NumDocumento = this.FacturasSeleccionadas[0]["NumDocumento"];
      //Se ejecuta la funcion para pasar al proceso de pago
      this.VerificaPago(NumDocumento);
    }
    else if(NumFacturas > 1)
    {
      this.IfFormPago = true;
      this.IfEstadoCuenta = false;
      this.spinnerService.hide();
    }
  }

  //-------------------------------------------------------------
  // Funcion para iniciar el proceso de pago
  //-------------------------------------------------------------
  InicioPago()
  {
    // Se verifican que los campos esten llenos
    if(this.Email !="" && this.TipoDocumento !="-1" && this.NumeroDocumento !="" && this.Nombres != "" && this.Apellidos != "" && this.Telefono > 0 && this.TotalPagar > 0 )
    {
      this.spinnerService.show();
      //Se valida el Email
      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.Email)){
       } else {
        this.spinnerService.hide();
        alert("La dirección de email es incorrecta.");
        return;
       }
       //Se valida el campo numero de documento
       if(/^([0-9])*$/i.test(this.NumeroDocumento))
       {}else{
        this.spinnerService.hide();
         alert("El campo numero de documento no debe contener letras.");
         return;
       }
      //Se valida el campo telefono
      if(/^([0-9])*$/i.test(this.Telefono.toString()))
      {}else{
        this.spinnerService.hide();
        alert("El campo telefono de documento no debe contener letras.");
        return;
      }

      //Se realiza el consumo para crear el registro de pago
      this.serviciosService.CreaCabeceraPago(this.NumeroDocumento,this.CodigoSap,this.TotalPagar,this.IvaPagar)
      .subscribe((data:[])=>{

        //Se crea el registro del pago
        var RespuestaRegistro = [];
        RespuestaRegistro = data;
        var VerificaRegistro = RespuestaRegistro['Error'];
        var IdPago = RespuestaRegistro['Id'];
        var DescPago = "Abono Numero "+ IdPago;

        // Se verifica la respuesta obtenida de creacion de registro
        if(VerificaRegistro == "0")
        {
          var Detalles = [];
          //Se agrega el Id a los detalles de pago
          for(var Registro in this.FacturasSeleccionadas)
          {
            Detalles.push({"IdPago":IdPago,"NumDocumento":this.FacturasSeleccionadas[Registro].NumDocumento,"Factura":this.FacturasSeleccionadas[Registro].Factura,"Valor":this.FacturasSeleccionadas[Registro].Valor,"Iva":this.FacturasSeleccionadas[Registro].Iva})
          }
          //Se realiza la almacenan los detalles del pago
          this.serviciosService.CreaDetallesPago(Detalles)
          .subscribe((data:[])=>{
            var InfoDetalles = [];
            InfoDetalles = data;
            
            var VerificaRespDetalles = InfoDetalles['Error'];
            //Se verifica la respuesta del servicio que crea los detalles del pedido
            if(VerificaRespDetalles == "0")
            {
              // Se realiza el consumo del servicio del inicio pagos
              this.serviciosService.InicioPagoZV(this.TotalPagar,this.IvaPagar,IdPago,DescPago,this.Email,this.NumeroDocumento,this.TipoDocumento,this.Nombres,this.Apellidos,this.Telefono)
              .subscribe((data:[])=>{
                var InicioPagos = [];
                var CodigoInicio = [];
                InicioPagos = data;
                CodigoInicio = InicioPagos["soap:Envelope"]["soap:Body"][0]["inicio_pagoV2Response"][0]["inicio_pagoV2Result"][0];
                //Productivo
                window.location.href ="https://www.zonapagos.com/t_Marpicopas/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio;
                //Calidad
                //window.location.href ="https://www.zonapagos.com/t_Marpicosa2/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio;

                this.router.navigate(['inicio']);
              });
            }
            else{
              this.spinnerService.hide();
              Swal.fire({
                title: 'Alerta',
                text: 'Se presentaron Errores con los pagos seleccionados intente de nuevo.',
                icon: 'warning'
              });
            }
          });
        }
        else{
          this.spinnerService.hide();
          Swal.fire({
            title: 'Alerta',
            text: 'Se presentaron Errores con los pagos Seleccionados intente de nuevo.',
            icon: 'warning'
          });
        }
      });
    }
    else{
      this.spinnerService.hide();
      Swal.fire({
        title: 'Alerta',
        text: 'Faltan campos por llenar, por favor verifique.',
        icon: 'warning'
      });
    }
  }
}
