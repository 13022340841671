import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router,ParamMap} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-proceso-pago',
  templateUrl: './proceso-pago.component.html',
  styleUrls: ['./proceso-pago.component.css']
})
export class ProcesoPagoComponent implements OnInit {

  //Se realiza la creación de las variables globales
  CodigoSap:string="";
  InfoCliente:string = "";
  Email:string = "";
  Telefono:number;
  NumeroDocumento:string = "";
  TipoDocumento:string = "-1";
  Nombres:string = "";
  Apellidos:string = "";
  ValorPagar:number;
  Identificacion:string = "";

  constructor(private serviciosService:ServiciosService,private activatedRoute:ActivatedRoute,private router:Router,private spinnerService: NgxSpinnerService) { }

  // Función de inicio de la pagina
  ngOnInit() {

    // Se catura cambio de parametro
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
      this.spinnerService.show();
      //Se captura el Id del Catalogo
      this.Identificacion = params.get('Id');
      // Se realiza verificacion del nit
      this.serviciosService.getConsultarCliente(this.Identificacion)
      .subscribe((data:[])=>{
        var RespuestaVerificacion = [];
        RespuestaVerificacion = data;
        var Error = RespuestaVerificacion['Error'];
        this.CodigoSap = RespuestaVerificacion['CodigoSap'];
        var NombreCompleto = RespuestaVerificacion['NombreCompleto'].toUpperCase();
        this.InfoCliente = this.Identificacion +" - "+ NombreCompleto;

        // Se valida si hay error
        if(Error == "1")
        {
          this.spinnerService.hide();
          this.router.navigate(['inicio']);
        }
        if(this.CodigoSap == "")
        {
          this.spinnerService.hide();
          this.router.navigate(['inicio']);
        }
        this.spinnerService.hide();
      });

    });
  }

  //-------------------------------------------------------------
  // Funcion para iniciar el proceso de pago
  //-------------------------------------------------------------
  InicioPago()
  {
    
    // Se verifican que los campos esten llenos
    if(this.Email !="" && this.TipoDocumento !="-1" && this.NumeroDocumento !="" && this.Nombres != "" && this.Apellidos != "" && this.Telefono > 0 && this.ValorPagar > 0 )
    {
      this.spinnerService.show();

      //Se valida el Email
      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.Email)){
       } else {
        this.spinnerService.hide();
        alert("La dirección de email es incorrecta.");
        return;
       }
       //Se valida el campo numero de documento
       if(/^([0-9])*$/i.test(this.NumeroDocumento))
       {}else{
        this.spinnerService.hide();
         alert("El campo numero de documento no debe contener letras.");
         return;
       }
        //Se valida el campo telefono
        if(/^([0-9])*$/i.test(this.Telefono.toString()))
        {}else{
          this.spinnerService.hide();
          alert("El campo telefono de documento no debe contener letras.");
          return;
        }
        //Se valida el valor a pagar
        if(/^([0-9])*$/i.test(this.ValorPagar.toString()))
        {}else{
          this.spinnerService.hide();
          alert("El campo valor a pagar de documento no debe contener letras.");
          return;
        }

      // Se realiza la consulta del token
      this.serviciosService.getConsultarToken()
      .subscribe((data:[])=>{
        // captura la respuesta del servicio
        var RespuestaToken = data;
        var Verifica = RespuestaToken['Error'];
        var Token = RespuestaToken['Token'];

        // Se verifica la respuesta del servicio del token
        if(Verifica == "0")
        {
          //Se realiza el consumo para crear el registro de pago
          this.serviciosService.getCrearRegistroPago(this.NumeroDocumento,this.CodigoSap,this.ValorPagar)
          .subscribe((data:[])=>{
            //Se crea el registro del pago
            var RespuestaRegistro = [];
            RespuestaRegistro = data;
            var VerificaRegistro = RespuestaRegistro['Error'];
            var IdPago = RespuestaRegistro['Id'];
            var DescPago = "Abono Numero "+ IdPago;

            // Se verifica la respuesta obtenida de creacion de registro
            if(VerificaRegistro == "0")
            {
              //Se ejecuta el servicio para amacenar la informacion en la sonda
              this.serviciosService.getAlmacenarPagoSonda(Token,IdPago,this.Identificacion,this.CodigoSap,"1")
              .subscribe((data:[])=>{
                var RespuentaPagoSonda = [];
                RespuentaPagoSonda = data;
                var VerificaRespuestaSonda = RespuentaPagoSonda['Error'];
                
                // Se verifica la respuesta del servicio
                if(VerificaRespuestaSonda == "0")
                {
                  // Se realiza el consumo del servicio del inicio pagos
                  this.serviciosService.InicioPagoZV(this.ValorPagar,0,IdPago,DescPago,this.Email,this.NumeroDocumento,this.TipoDocumento,this.Nombres,this.Apellidos,this.Telefono)
                  .subscribe((data:[])=>{
                    var InicioPagos = [];
                    var CodigoInicio = [];
                    InicioPagos = data;
                    CodigoInicio = InicioPagos["soap:Envelope"]["soap:Body"][0]["inicio_pagoV2Response"][0]["inicio_pagoV2Result"][0];
                    //Productivo
                    window.location.href ="https://www.zonapagos.com/t_Marpicopas/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio;
                    //Calidad
                    //window.open("https://www.zonapagos.com/t_Marpicosa2/pago.asp?estado_pago=iniciar_pago&identificador="+CodigoInicio);
                    this.router.navigate(['inicio']);
                  });
                }
                else{
                  this.spinnerService.hide();
                  alert("Se presento un error intente mas tarde.")
                }
              });

            }
            else{
              this.spinnerService.hide();
              alert("se presento un Error con el inicio de pago, intente mas tarde. Registro Base de Datos")
            }
          });
        }
        else{
          this.spinnerService.hide();
          alert("se presento un Error con el inicio de pago, intente mas tarde. ERROR TOKEN")
        }
      });
    }
    else{
      this.spinnerService.hide();
      alert("Faltan campos por llenar, por favor verifique.")
    }
  }
}
