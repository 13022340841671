import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { ProcesoPagoComponent } from './proceso-pago/proceso-pago.component';
import { OpcionesPagoComponent } from './opciones-pago/opciones-pago.component';
import { EstadoCuentaComponent } from './estado-cuenta/estado-cuenta.component';
import { PagoFacturaComponent } from './pago-factura/pago-factura.component';


const routes: Routes = [
  {path: '',component:InicioComponent},
  {path: 'inicio',component:InicioComponent},
  {path: 'procesoPago/:Id', component:ProcesoPagoComponent},
  {path: 'opcionesPago/:Id',component:OpcionesPagoComponent},
  {path: 'estadoCuenta/:Id/:Codigo',component:EstadoCuentaComponent},
  {path: 'pagoFactura/:Id/:NumDocumento/:Codigo',component:PagoFacturaComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
