import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiciosService {

  constructor(private http:HttpClient) { }

  // Se crea la Url del api
  //Calidad
  //URL:string = "http://192.168.11.16:3700/";
  //Productivo
  URL:string = "https://appay.marpico.co/";
  Clave: string = "MaR*p.2023.P4g0+*.$";

  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  getVerificaNit(Identificacion:string){
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'verificaNitN?Identificacion='+Identificacion,{ headers: headers });
  }

  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  getConsultarCliente(Identificacion:string){
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'consultarClienteN?Codigo='+Identificacion,{ headers: headers });
  }

  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  getConsultarToken(){
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'consultaTokenN',{ headers: headers });
  }

  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  getCrearRegistroPago(Identificacion:string,CodigoSap:string,Total:number){
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'crearRegistroPagoN?Identificacion='+Identificacion+'&CodigoSap='+CodigoSap+'&Total='+Total,{ headers: headers });
  }

  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  getAlmacenarPagoSonda(Token:string,ReferenciaPago:string,IdentificacionCliente:string,CodigoCliente:string,Anticipo:string){
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'almacenarPagoSondaN?Token='+Token+'&ReferenciaPago='+ReferenciaPago+'&IdentificacionCliente='+IdentificacionCliente+'&CodigoCliente='+CodigoCliente+'&Anticipo='+Anticipo,{ headers: headers });
  }

  //-----------------------------------------------------------------------------------
  // Servicio para el inicio pagos
  //-----------------------------------------------------------------------------------
  InicioPagoZV(Total:number,Iva:number,IdPedido:string,DescPedido:string,Email:string,Identificacion:string,TipoDocumento:string,Nombres:string,Apellidos:string,Telefono:number)
  {
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'inicioPagosN?Total='+Total+'&Iva='+Iva+'&IdPedido='+IdPedido+'&DescPedido='+DescPedido+'&Email='+Email+'&Identificacion='+Identificacion+'&TipoDocumento='+TipoDocumento+'&Nombres='+Nombres+'&Apellidos='+Apellidos+'&Telefono='+Telefono,{ headers: headers });
  }

  //-----------------------------------------------------------------------------------
  // Servicio para consultar el estado de cuenta
  //-----------------------------------------------------------------------------------
  EstadoCuenta(Codigo:string,Fecha:string)
  {
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'estadoCuentaN?Codigo='+Codigo+'&Fecha='+Fecha,{ headers: headers });
  }
  //-----------------------------------------------------------------------------------
  // Servicio para consultar el estado de cuenta
  //-----------------------------------------------------------------------------------
  ConsultaFactura(Codigo:string,NumDocumento:string)
  {
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'consultaFacturaN?Codigo='+Codigo+'&NumDocumento='+NumDocumento,{ headers: headers });
  }

  //-----------------------------------------------------------------------------------
  // Servicio para consultar el estado de cuenta
  //-----------------------------------------------------------------------------------
  ConsultaEstadoPago(NumDocumento:string)
  {
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'consultarEstadoPagoN?NumDocumento='+NumDocumento,{ headers: headers });
  }

  //-----------------------------------------------------------------------------------
  // Servicio para generar y enviar el codigo de seguridad al correo
  //-----------------------------------------------------------------------------------
  GeneraCodigo(CodigoSap:string)
  {
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'generaCodigoN?CodigoSap='+CodigoSap,{ headers: headers });
  }

  //-----------------------------------------------------------------------------------
  // Servicio para generar y enviar el codigo de seguridad al correo
  //-----------------------------------------------------------------------------------
  VerificaCodigo(Identificacion:string,CodigoSeguridad:string)
  {
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'verificaCodigoN?Identificacion='+Identificacion+'&CodigoSeguridad='+CodigoSeguridad,{ headers: headers });
  }
  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  CreaCabeceraPago(Identificacion:string,CodigoSap:string,Total:number,Iva:number){
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'creaCabeceraPagoN?Identificacion='+Identificacion+'&CodigoSap='+CodigoSap+'&Total='+Total+'&Iva='+Iva,{ headers: headers });
  }
  //-----------------------------------------------------------------------------------
  // Servicio para crear los detalles del pedido
  //-----------------------------------------------------------------------------------
  CreaDetallesPago(Detalles:any[])
  {
    var json = JSON.stringify(Detalles);
    var params = "json="+ json;
    //var headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    var headers = new HttpHeaders({ 'access-token': this.Clave, 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(this.URL + 'creaDetallesPagoN',params,{headers:headers});
  }
  //------------------------------------------------------------------------
  // Servicio para verificar si e nit existe
  //------------------------------------------------------------------------
  ConsultaDocAgrupado(NumDocumento:string){
    var headers = new HttpHeaders().set('access-token', this.Clave);
    return this.http.get(this.URL + 'consultaDocPagoAgrupadoN?NumDocumento='+NumDocumento,{ headers: headers });
  }
}
