import { Component, OnInit } from '@angular/core';
import { ServiciosService } from '../services/servicios.service';
import { ActivatedRoute, Router,ParamMap} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-opciones-pago',
  templateUrl: './opciones-pago.component.html',
  styleUrls: ['./opciones-pago.component.css']
})
export class OpcionesPagoComponent implements OnInit {

  // Se crean las variables generales
  Identificacion:string = "";
  CodigoSap:string="";
  InfoCliente:string = "";
  NombreCompleto:string = "";
  IfInicio:boolean = true;
  IfCodigo:boolean = false;
  CodigoSeguridad:string = "";
  Correo:string = "";
  CodigoSeguridadVerifica = "";

  constructor(private serviciosService:ServiciosService,private activatedRoute:ActivatedRoute,private router:Router,private spinnerService: NgxSpinnerService) { }

  //Funcion de Inicio
  ngOnInit() {
    // Se catura cambio de parametro
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
      //Se captura el Id del Catalogo
      this.Identificacion = params.get('Id');
      this.spinnerService.show();
      // Se realiza verificacion del nit
      this.serviciosService.getConsultarCliente(this.Identificacion)
      .subscribe((data:[])=>{
        var RespuestaVerificacion = [];
        RespuestaVerificacion = data;
        var Error = RespuestaVerificacion['Error'];
        this.CodigoSap = RespuestaVerificacion['CodigoSap'];
        this.NombreCompleto = RespuestaVerificacion['NombreCompleto'].toUpperCase();
        this.InfoCliente = this.Identificacion +" - "+ this.NombreCompleto;

        // Se valida si hay error
        if(Error == "1")
        {
          this.spinnerService.hide();
          this.router.navigate(['inicio']);
        }
        if(this.CodigoSap == "")
        {
          this.spinnerService.hide();
          this.router.navigate(['inicio']);
        }
        this.spinnerService.hide();
      });

    });
  }
  //----------------------------------------------------------------
  //Funcion para generar y enviar el codigo de seguridad
  //----------------------------------------------------------------
  GeneraCodigo()
  {
    var InfoSeguridad = [];
    var Error:string = "";
    this.spinnerService.show();
    // se llama el servicio que genera y envia el codigo de seguridad
    this.serviciosService.GeneraCodigo(this.CodigoSap)
    .subscribe((data:[])=>{
      InfoSeguridad = data;
      Error = InfoSeguridad['Error'];
      //Se verifica si se presento algun error
      if(Error == "0")
      {
        //Se asignan las variables
        this.Correo = InfoSeguridad['Correo'];
        this.CodigoSeguridadVerifica = InfoSeguridad['CodigoSeguridad'];

        //se habilita el contenedor del codigo
        this.IfInicio = false;
        this.IfCodigo = true;
      }
      else{
        alert("No se pudo generar el codigo, por favor intente de nuevo o comuniquese con el area de atención al cliente.")
      }
      this.spinnerService.hide();
    });
  }
    //----------------------------------------------------------------
  //Funcion para generar y enviar el codigo de seguridad
  //----------------------------------------------------------------
  VerificaCodigo()
  {
    //Se verifica si coinciden los codigos de seguridad
    if(this.CodigoSeguridad == this.CodigoSeguridadVerifica)
    {
      this.router.navigate(['estadoCuenta/'+this.Identificacion+'/'+this.CodigoSeguridad]);
    }
    else{
      alert("El Codigo de Seguridad no es valido");
    }

  }
}
