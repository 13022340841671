import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { NgxSpinnerModule,NgxSpinnerService } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';
import { ProcesoPagoComponent } from './proceso-pago/proceso-pago.component';
import { OpcionesPagoComponent } from './opciones-pago/opciones-pago.component';
import { EstadoCuentaComponent } from './estado-cuenta/estado-cuenta.component';
import { PagoFacturaComponent } from './pago-factura/pago-factura.component';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    ProcesoPagoComponent,
    OpcionesPagoComponent,
    EstadoCuentaComponent,
    PagoFacturaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},NgxSpinnerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
